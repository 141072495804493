/* eslint-disable */
import React from "react"
import { graphql } from 'gatsby'
import Layout from '../../components/theme/Layout'
import Footer from '../../components/theme/Footer'
import Header from '../../components/theme/Header'
import RenderBlock from '../../components/blocks/RenderBlock'

import EventTracker from "../../components/common/EventTracker";
import Offers from "../../components/common/Offers";
import ImageRightTextLeft from "../../components/blocks/ImageRightTextLeftBlock";
import fixImageUrl from "../../../plugins/gatsby-source-episerver/fixImageUrl";

const AboutRoyale = ({ data }) => {
  let image3 = fixImageUrl("../../../assets/images/Web_ClusterPacks_Purr-oudlyCanadian.jpg");
  let image3Text = [
    {
      "__typename": "HeaderBlock",
      "type": "h2",
      "title": "Une entreprise fièrement canadienne.",
      "guid": "image3header"
    },
    {
      "__typename": "TextBlock",
      "text": "Nous sommes une compagnie canadienne qui fabrique des produits de qualité, auxquels les utilisateurs font confiance pour se faciliter la vie au quotidien. Nous sommes ravis de faire partie d’une équipe aussi passionnée que diversifiée. Chaque jour, nous faisons face à la concurrence (et connaissons du succès) à l’échelle internationale.",
      "guid": "image3text"
    }
  ];

  // Remove some content blocks
  // I do not want to remove them from Episerver because the client will ask us to put them all back
  // Just remove this when we need to put the content back
  const blocks = data.defaultPage.blocks;
  blocks.shift();
  blocks.shift();
  blocks.shift();
  blocks.shift();
  blocks.shift();

  const urls = data.defaultPage.urls;
  urls.en = '/about'
  urls.fr = '/fr/a-propos-de-royale'

  return (
    <Layout
      meta={data.defaultPage.meta}
      urls={urls}
      locale='fr'
    >
      <Header
        urls={urls}
        locale='fr'
      />
      <main>
        <ImageRightTextLeft alt="Une compagnie fièRRRement canadienne" image={image3} left={image3Text} caption="Faison – Opérateur, usine de Toronto" modification="center morepadding" />

        <RenderBlock blocks={blocks} main={false} />
      </main>

      <EventTracker gtag={{'allow_custom_scripts': true,'send_to': 'DC-8521957/invmedia/tiger0+standard'}} />
      <img height="1" width="1" style={{"border-style":"none"}} alt="Royale" src="https://insight.adsrvr.org/track/pxl/?adv=7c99unf&ct=0:14yinok&fmt=3" />
      <Offers />
      <Footer showExtraLegal />
    </Layout>
  )
}

export const query = graphql`
query {
  defaultPage(guid: { eq:"5c1c9570-0086-4a6f-ac11-7460dc6481ac"}, locale: {eq:"fr"}) {
    title
    title_html
    subtitle
    description
    image_url
    blocks {
      __typename
      ...textBlockFragment
      ...imageLeftTextRightBlockFragment
      ...imageRightTextLeftBlockFragment
      ...videoBlockFragment
      ...imageBlockFragment
      ...threeColumnWithIconsBlockFragment
    }
    urls {
      en
      fr
      zh_hant
      zh_hans
    }
    meta {
      description
      keywords
      title
    }
  }
}
`

export default AboutRoyale
